<template>
    <div>
        <v-dialog v-model="dialog" width="500">
            <v-card>
                <v-card-title class="headline grey lighten-2">
                    Новый администратор
                </v-card-title>

                <v-card-text>
                    <v-text-field class="mt-5" v-model="newAdmin.name" label="Имя" dense outlined></v-text-field>
                    <v-text-field v-model="newAdmin.surname" label="Фамилия" dense outlined></v-text-field>
                    <v-text-field v-model="newAdmin.phone" label="Телефон" dense outlined></v-text-field>
                    <v-text-field v-model="newAdmin.mail" label="Email" dense outlined></v-text-field>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text v-on:click="addAdmin">Добавить</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-row no-gutters fluid class="spacing-playground my-6 p-0">
            <v-col cols="12" sm="8">
                <div class="text-sm-h4">Администраторы</div>
            </v-col>
            <v-col cols="12" sm="4" align="right">
                <v-btn color="primary" elevation="1" large v-on:click="dialog = true">Добавить</v-btn>
            </v-col>
        </v-row>
        <v-card outlined>
            <v-simple-table>
                <template v-slot:default>
                    <thead>
                    <tr>
                        <th class="text-left"></th>
                        <th class="text-left">Админ</th>
                        <th class="text-left">Телефон</th>
                        <th class="text-left">Email</th>
                        <th class="text-left"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(admin, index) in admins">
                        <td>{{index+1}}</td>
                        <td>{{admin.name}} {{admin.surname}}</td>
                        <td>{{admin.phone}}</td>
                        <td>{{admin.mail}}</td>
                        <td style="text-align: end;">
                            <v-icon color="gray" v-on:click="delAdmin(admin.id)">mdi-delete-circle-outline</v-icon>
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>
            <v-progress-linear indeterminate v-if="!admins.length"></v-progress-linear>

        </v-card>

    </div>
</template>

<script>
    import { db } from '../firebase';

    export default {
        name: "Admins",

        data() {
            return {
                dialog: false,
                newAdmin: {phone: '+380'},
                admins: null,
            }
        },
        methods: {
            delAdmin(id) {
                let that = this;
                if(confirm('Удалить администратора?')) {
                    db.collection('admins').doc(id).delete().then(function () {
                        that.$store.commit('alert', { message: 'Администратор удален'});
                    }).catch(function () {
                        that.$store.commit('alert', { message: 'Ошибка. Администратор не удален', type: 'error'});
                    })
                }
            },
            addAdmin() {
              let that = this;
              db.collection('admins').add(that.newAdmin).then(function (e) {
                  that.dialog = false;
                  that.newAdmin = {};
                  that.$store.commit('alert', { message: 'Администратор добавлен'});

              }).catch(function (e) {
                  that.dialog = false;
                  that.$store.commit('alert', { message: 'Ошибка. Администратор не добавлен', type: 'error'});
              })
            },
        },

        mounted() {
        },
        firestore: {
            admins: db.collection('admins')
        },
    }
</script>

<style scoped>

</style>
